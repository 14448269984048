@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
   font-family: 'Muli Regular';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Regular'), url('../../public/fonts/Muli-Regular.woff') format('woff');
}

@font-face {
   font-family: 'Muli Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Italic'), url('../../public/fonts/Muli-Italic.woff') format('woff');
}

@font-face {
   font-family: 'Muli ExtraLight';
   font-style: normal;
   font-weight: normal;
   src: local('Muli ExtraLight'), url('../../public/fonts/Muli-ExtraLight.woff') format('woff');
}

@font-face {
   font-family: 'Muli ExtraLight Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli ExtraLight Italic'), url('../../public/fonts/Muli-ExtraLightItalic.woff') format('woff');
}

@font-face {
   font-family: 'Muli Light';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Light'), url('../../public/fonts/Muli-Light.woff') format('woff');
}

@font-face {
   font-family: 'Muli Light Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Light Italic'), url('../../public/fonts/Muli-LightItalic.woff') format('woff');
}

@font-face {
   font-family: 'Muli SemiBold';
   font-style: normal;
   font-weight: normal;
   src: local('Muli SemiBold'), url('../../public/fonts/Muli-SemiBold.woff') format('woff');
}

@font-face {
   font-family: 'Muli SemiBold Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli SemiBold Italic'), url('../../public/fonts/Muli-SemiBoldItalic.woff') format('woff');
}

@font-face {
   font-family: 'Muli Bold';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Bold'), url('../../public/fonts/Muli-Bold.woff') format('woff');
}

@font-face {
   font-family: 'Muli Bold Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Bold Italic'), url('../../public/fonts/Muli-BoldItalic.woff') format('woff');
}

@font-face {
   font-family: 'Muli ExtraBold';
   font-style: normal;
   font-weight: normal;
   src: local('Muli ExtraBold'), url('../../public/fonts/Muli-ExtraBold.woff') format('woff');
}

@font-face {
   font-family: 'Muli ExtraBold Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli ExtraBold Italic'), url('../../public/fonts/Muli-ExtraBoldItalic.woff') format('woff');
}

@font-face {
   font-family: 'Muli Black';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Black'), url('../../public/fonts/Muli-Black.woff') format('woff');
}

@font-face {
   font-family: 'Muli Black Italic';
   font-style: normal;
   font-weight: normal;
   src: local('Muli Black Italic'), url('../../public/fonts/Muli-BlackItalic.woff') format('woff');
}

.smooth-shadow {
box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.00),
   0px 5px 4px rgba(3, 7, 18, 0.01),
   0px 12px 9px rgba(3, 7, 18, 0.01),
   0px 20px 15px rgba(3, 7, 18, 0.02),
   0px 32px 24px rgba(3, 7, 18, 0.02);
}

.light-shadow {
   box-shadow: 0px 8px 6px rgba(3, 7, 18, 0.01),
   0px 32px 24px rgba(3, 7, 18, 0.01);
}

/* HTML: <div class="loader"></div> */
.loader {
   --d:22px;
   width: 4px;
   height: 4px;
   border-radius: 50%;
   color: #09cc7f;
   box-shadow: 
      calc(1*var(--d))      calc(0*var(--d))     0 0,
      calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
      calc(0*var(--d))      calc(1*var(--d))     0 2px,
      calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
      calc(-1*var(--d))     calc(0*var(--d))     0 4px,
      calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
      calc(0*var(--d))      calc(-1*var(--d))    0 6px;
   animation: l27 1s infinite steps(8);
}

@keyframes l27 {
   100% {transform: rotate(1turn)}
}